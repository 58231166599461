import {
  documentsRequest, signDocumentRequest,
} from '@/requestManager/cabinet/documents';

export const state = () => ({
  documents: [],
});

export const getters = {};

export const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },
};

export const actions = {
  // получение документов
  async getDocumentsRequest({ commit }) {
    const userToken = this.$cookies.get('accessToken');

    try {
      const response = await documentsRequest(userToken);

      if (response.data.success) {
        commit('SET_DOCUMENTS', response.data.data);
      }
    } catch (e) {
      console.log(e)
    }
  },

  // подписание документов
  async signRequest({ commit }, id) {
    return await signDocumentRequest(id);
  },
};