import {
  getConfirmProfileRequest,
  confirmProfileRequest,
} from '@/requestManager/cabinet/security/confirm-profile';

export const state = () => ({
  confirmedProfile: {},
});

export const getters = {};

export const mutations = {
  SET_CONFIRMED_PROFILE(state, profile) {
    state.confirmedProfile = profile;
  },
};

export const actions = {
  async getConfirmedProfile({ commit }) {
    try {
      const response = await getConfirmProfileRequest();

      if (response.data.success) {
        commit('SET_CONFIRMED_PROFILE', response.data.data);
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },

  async updateConfirmProfile({ commit }, form) {
    return await confirmProfileRequest(form);
  },
};