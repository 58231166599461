import requestManager from '@/requestManager/requestManager';

const GET_TRANSACTIONS    = '/api/user/transactions';
const SEND_AMOUNT_DEPOSIT = '/api/user/wallet/invest-request';
const METAMASK_TRANSACTION = '/api/user/metamask-transaction';
const METAMASK_TRANSACTION_UPDATE = '/api/user/metamask-transaction/update';


// получение истории транзакций
export const transactionsRequest = () =>
  requestManager.get(GET_TRANSACTIONS);

// отправка запроса на пополнение счета
export const amountDepositRequest = (form) =>
  requestManager.post(SEND_AMOUNT_DEPOSIT, form);

export const metamaskTransactionDepositRequest = (form) =>
  requestManager.post(METAMASK_TRANSACTION, form);

export const metamaskTransactionUpdateDepositRequest = (form) =>
  requestManager.put(METAMASK_TRANSACTION_UPDATE, form);
