import requestManager from '@/requestManager/requestManager';

const GET_PROFILE_IMAGE_REQUEST = 'api/images/crop';
const UPDATE_PROFILE_REQUEST = 'api/user/profile';

// получение фото профиля
export const uploadProfileImageRequest = (form) => {
  return requestManager.post(GET_PROFILE_IMAGE_REQUEST, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// обновление профиля
export const updateProfileDataRequest = (form) => {
  return requestManager.put(UPDATE_PROFILE_REQUEST, {
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    phone: form.phone,
  });
};

export const updateProfilePhotoRequest = (avatar) => {
  return requestManager.put(UPDATE_PROFILE_REQUEST, {
    avatar: {
      id: avatar?.id || null,
      url: avatar?.url || null,
    },
  });
};

// обновление персональной информации
export const uploadPersonalInformationRequest = (form) =>
  requestManager.put(UPDATE_PROFILE_REQUEST, form);
