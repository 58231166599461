import {
  statisticsRequest,
} from '@/requestManager/cabinet/home';

export const state = () => ({
  statistics: [],
});

export const getters = {};

export const mutations = {
  SET_STATISTICS(state, statistics) {
    state.statistics = statistics;
  },
};

export const actions = {
  // получение статистики
  async getMainStatisticsRequest({ commit }) {
    try {
      const response = await statisticsRequest();

      if (response.data.success) {
        commit('SET_STATISTICS', response.data.data);
        console.log('SET_STATISTICS', response.data.data);

      }
    } catch (e) {
      console.log(e)
    }
  },
};