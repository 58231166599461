export const state = () => ({
  isOpened: true,
});

export const mutations = {
  TOGGLE_NAVIGATION(state) {
    state.isOpened = !state.isOpened;
  },
  CLOSE_NAVIGATION(state) {
    state.isOpened = false;
  },
};
