import requestManager from '@/requestManager/requestManager';

const GET_STRATEGY           = '/api/invests/report/${name}';
const GET_STRATEGIES         = '/api/strategies';
const INVEST_TO_STRATEGY     = '/api/invests/invest-to-strategy/${name}';
const WITHDRAW_FROM_STRATEGY = '/api/invests/close-invest/${name}';
const GET_STRATEGY_GRAPHIC   = '/api/invests/graphics/${name}';

// получение стратегий
export const strategiesRequest = () =>
  requestManager.get(GET_STRATEGIES);

// получение стратегии
export const getStrategyRequest = (alias, token) =>
  requestManager.get(GET_STRATEGY.replace('${name}', alias),
    {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    },
  );

// инвестирование в стратегию
export const investToStrategyRequest = (form) =>
  requestManager.post(INVEST_TO_STRATEGY.replace('${name}', form.strategyAlias),
    {
      amount: form.amount,
      password: form.password,
    },
  );

// вывод из стратегию
export const withdrawFromStrategyRequest = (form) =>
  requestManager.post(WITHDRAW_FROM_STRATEGY.replace('${name}', form.strategyAlias),
    {
      amount: form.amount,
      password: form.password,
    },
  );

// получение графика
export const getStrategyGraphicRequest = (alias, period, timezone) =>
  requestManager.get(GET_STRATEGY_GRAPHIC.replace('${name}', alias),
    {
      params: {
        period: period,
        timezone: timezone,
      },
    },
  );