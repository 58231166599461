import {
  getStrategyRequest,
  getStrategyGraphicRequest,
} from '@/requestManager/cabinet/services';

export const state = () => ({
  strategy: [],
  graphics: [],
  labels: [],
});

export const getters = {
  isStrategyInvested: state => state.strategy.investParams.invested,
  getStatisticsByStrategy: state => state.strategy.investParams.stats,
  getTransactionsByStrategy: state => state.strategy.investParams.transactions,
  getInvestingParams: state => ({
    name: state.strategy.title,
    alias: state.strategy.alias,
    description:state.strategy.Description
  }),
  getInvestments: state => state.strategy.investParams.balance,
};

export const mutations = {
  SET_GRAPHICS_DATA(state, graphics) {
    state.graphics = [];
    state.labels = graphics[0].graphic.labels;

    graphics.forEach(graphic => {
      if (graphic.graphic.values.length) {
        state.graphics.push(graphic.graphic.values);
      }
    });
  },
  SET_STRATEGY(state, strategy) {
    state.strategy = strategy;
  },
};

export const actions = {
  // получение графика
  async getStrategyGraphic({ commit }, params) {

    try {
      const response = await getStrategyGraphicRequest(params.alias, params.period, params.timezone);

      if (response.data.success) {
        commit('SET_GRAPHICS_DATA', response.data.data);
      }

      return response;
    } catch (e) {
      console.error(e);
    }
  },

  // получение стратегии
  async fetchStrategy({ commit }, params) {
    try {
      const response = await getStrategyRequest(params.alias, params.token);

      if (response.data.success) {
        commit('SET_STRATEGY', response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  },
};
