import {
  getCountriesRequest,
  getCitiesRequest,
} from '@/requestManager/cabinet/main';

export const state = () => ({
  countries: [],
  cities: [],
});

export const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  SET_CITIES_BY_COUNTRY(state, cities) {
    state.cities = cities;
  },
};

export const actions = {
  // получение стран
  async getCountries({ commit }) {
    try {
      const response = await getCountriesRequest();

      if (response.data.success) {
        commit('SET_COUNTRIES', response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  },

  // получение городов от страны
  async fetchCitiesByCountry({ commit }, [countryId, citySearch]) {
    try {
      const response = await getCitiesRequest([countryId, citySearch]);

      if (response.data.success) {
        commit('SET_CITIES_BY_COUNTRY', response.data.data);
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },
}