import {
  getAccountVerificationFormInfo,
  get2FAInfoRequest,
} from '@/requestManager/cabinet/verifications';

export const state = () => ({
  verification: {},
  twoFAVerification: {},
});

export const getters = {
  verificationAccountFirstStepInfo: state => ({
    firstName: state.verification.firstName,
    lastName: state.verification.lastName,
    email: state.verification.email,
    countryId: state.verification.countryId,
    city: state.verification.city,
    street: state.verification.street,
    streetLineTwo: state.verification.streetLineTwo,
    postcode: state.verification.postcode,
  }),
  verificationAccountDocumentsInfo: state => state.verification.documents,
};

export const mutations = {
  SET_VERIFICATION(state, verification) {
    state.verification = verification;
  },
  DELETE_DOCUMENTS_IMAGE(state, id) {
    state.verification.documents.forEach(document => {
      if (document.id === null) return;

      if (document.id === id) {
        document.id = null;
        document.image = {
          id: null,
          name: null,
          size: null,
          extension: null,
          url: null,
        };
        document.name = null;
        document.response = null;
        document.status = null;
        document.message = null;
      }
    })
  },
  UPDATE_DOCUMENTS_IMAGE(state, [image, type, side]) {
    state.verification.documents.forEach(document => {
      if (document.documentType === type && document?.side === side) {
        document.id = image.id;
        document.image = image.image;
        document.name = image.name;
        document.response = image.response;
        document.status = image.status;
        document.message = image.message || null;
      }
    })
  },

  SET_TWO_FA_VERIFICATION(state, verification) {
    state.twoFAVerification = verification;
  },
};

export const actions = {
  async initAccountVerification({ commit }) {
    try {
      const response = await getAccountVerificationFormInfo();

      if (response.data.success) {
        commit('SET_VERIFICATION', response.data.data);
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchTwoFAInformation({ commit }) {
    try {
      const response = await get2FAInfoRequest();

      if (response.data) {
        commit('SET_TWO_FA_VERIFICATION', response.data);
      }

      return response;
    } catch (e) {
      console.error(e);
    }
  },
};
