import requestManager from '@/requestManager/requestManager';

const GET_DOCUMENTS_REQUEST   = 'api/documents';
const SIGN_DOCUMENT_REQUEST   = 'api/documents/${id}/sign';

// получение документов
export const documentsRequest = () =>
  requestManager.get(GET_DOCUMENTS_REQUEST);

// подписание дкоумента
export const signDocumentRequest = (id) =>
  requestManager.post(SIGN_DOCUMENT_REQUEST.replace('${id}', id));