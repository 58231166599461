import requestManager from '@/requestManager/requestManager';

const GET_WALLET_LIST_REQUEST   = 'api/user/wallets';
const CREATE_WALLET_REQUEST     = 'api/user/wallets/create';
const DELETE_WALLET_REQUEST     = 'api/user/wallets/${id}/delete';

// получение списка кошельков
export const getWalletListRequest = () =>
  requestManager.get(GET_WALLET_LIST_REQUEST);

// добаление записи в список кошельков
export const createWalletListRequest = (form) =>
  requestManager.post(CREATE_WALLET_REQUEST, form);

// удаление записи в списке кошельков
export const deleteWalletItemRequest = (id) =>
  requestManager.delete(DELETE_WALLET_REQUEST.replace('${id}', id));
