import { mainRequest } from '@/requestManager';

export const state = () => ({
  languages: [],
  fundWallets: [],
  mainUserInfo: {},
  google2FAInfo: {
    email: null,
    password: null,
    remember: null,
    step: false,
  },
});

export const getters = {
  getMainVerificationStatus: state => state.mainUserInfo.verification?.mainVerifyStatus,
}

export const mutations = {
  SET_LANGUAGES(state, languages) {
    state.languages = languages;
  },
  SET_FUND_WALLETS(state, wallets) {
    state.fundWallets = wallets;
  },
  SET_MAIN_INFO(state, info) {
    state.mainUserInfo = info;
  },

  SET_TWO_FA(state, value) {
    state.google2FAInfo = value;
    this.$cookies.set('active2FAStep', value);
  },

  RESET_TW0_FA(state) {
    state.google2FAInfo = {
      email: null,
      password: null,
      remember: null,
      step: false,
    };
    this.$cookies.remove('active2FAStep');
  },
};

export const actions = {
  async init({ commit }) {
    try {
      const response = await mainRequest();

      const mainData = response.data.data;

      if (response.data.success) {
        commit('SET_LANGUAGES', mainData.languages);
        commit('SET_FUND_WALLETS', mainData.fundWalletAddresses);
        commit('SET_MAIN_INFO', mainData.mainUserInfo);
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  },
};
