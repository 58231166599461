import requestManager from '@/requestManager/requestManager';

const GET_PROFILE_REQUEST       = 'api/user/profile';
const SIGN_OUT_REQUEST          = 'api/auth/sign-out';
const GET_COUNTRIES_REQUEST     = 'api/main/countries';
const GET_CITIES_REQUEST        = '/api/main/cities';
const DISABLE_DEMO_MODE         = '/api/user/profile';
const CHANGE_APP_LANGUAGE       = '/api/user/update/language';

// получение профиля
export const getProfileRequest = () =>
  requestManager.get(GET_PROFILE_REQUEST);

// выход пользователя
export const signOutRequest = () =>
  requestManager.post(SIGN_OUT_REQUEST);

// получение стран
export const getCountriesRequest = () =>
  requestManager.get(GET_COUNTRIES_REQUEST);

// получение городов
export const getCitiesRequest = ([countryId, citySearch]) =>
  requestManager.get(GET_CITIES_REQUEST,
    {
      params: {
        countryId: countryId,
        search: citySearch,
      },
    },
  );

// выключение демо режима
export const disableDemoModeRequest = (mode) =>
  requestManager.put(DISABLE_DEMO_MODE, {
    demoMode: mode,
  });

// выключение демо режима
export const changeAppLanguage = (lang) =>
  requestManager.post(CHANGE_APP_LANGUAGE, {
    languageCode: lang
  });