/*
 * GC - Загрузчик файлов
 *
 * @constructor
 * @param {Array} fileList - Массив файлов типа FileList
 */

export default class FileLoader {
  #fileList
  #files = [];

  #options = {
    isMultipleFiles: false,
  };

  constructor({ fileList }) {
    this.#fileList = fileList;
  }

  async init() {
    this.#files = await Promise.all(Array
      .from(this.#fileList)
      .map(file => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);

          fileReader.onload = () => {
            resolve({
              name: file.name,
              size: file.size,
              type: file.type,
              base64: fileReader.result,
              value: file,
            });
          };

          fileReader.onerror = error => reject(error);
        });
      }));
  }

  get getFiles() {
    return this.#files;
  }

  get getFile() {
    return this.#files[0];
  };
}