import {
  faqRequest,
} from '@/requestManager/cabinet/faq';

export const state = () => ({
  questions: [],
});

export const getters = {};

export const mutations = {
  SET_QUESTIONS(state, questions) {
    state.questions = questions;
  },
};

export const actions = {
  // получение вопросов и ответов
  async getFaqRequest({ commit }) {
    try {
      const response = await faqRequest();

      if (response.data.success) {
        commit('SET_QUESTIONS', response.data.data);
      }
    } catch (e) {
      console.log(e)
    }
  },
}