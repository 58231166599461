import {
  updateProfileDataRequest,
  uploadPersonalInformationRequest,
  updateProfilePhotoRequest,
} from '@/requestManager/cabinet/settings/profile';

import {
  getProfileRequest,
} from '@/requestManager/cabinet/main';

export const state = () => ({
  user: null,
});

export const getters = {
  demoModeStatus: state => state.user.demoMode,
  getProfileBalance: state => state.user.balance,
  mainAccountVerification: state => state.user.verification?.mainVerifyStatus,
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },

  // обновление полей в settings/profile
  UPDATE_USER_NAME(state, firstName) {
    state.user.firstName = firstName;
  },
  UPDATE_USER_SURNAME(state, lastName) {
    state.user.lastName = lastName;
  },
  UPDATE_USER_LOGO(state, [imageId, imageUrl]) {
    state.user.avatar.id = imageId;
    state.user.avatar.url = imageUrl;
  },
  REMOVE_USER_LOGO(state) {
    state.user.avatar.id = null;
    state.user.avatar.url = null;
  },

  // обновление полей в settings/information
  UPDATE_USER_PHONE(state, phone) {
    state.user.phone = phone;
  },
  UPDATE_USER_EMAIL(state, phone) {
    state.user.email = phone;
  },
  UPDATE_USER_STREET(state, street) {
    state.user.street = street;
  },
  UPDATE_USER_SECOND_STREET(state, streetLineTwo) {
    state.user.streetLineTwo = streetLineTwo;
  },
  UPDATE_USER_POSTCODE(state, postcode) {
    state.user.postcode = postcode;
  },
  UPDATE_USER_COUNTRY(state, country) {
    state.user.countryId = country;
    state.user.city.id = null;
    state.user.city.name = null;
  },
  UPDATE_USER_CITY(state, city) {
    state.user.city.id = city.id;
    state.user.city.name = city.name;
  },

  DISABLE_DEMO_MODE(state) {
    state.user.demoMode = false;
  },

  UPDATE_USER_BALANCE_AFTER_TRANSACTIONS(state, value) {
    state.user.balance = value;
  },
};

export const actions = {
  // изминение полей формы профиля
  async updateProfileMainSettings({ commit }, { firstName, lastName, email, phone }) {
    return await updateProfileDataRequest({ firstName, lastName, email, phone });
  },

  // изминение полей формы персональной информации
  async uploadPersonalInformationSettings({ commit }, form) {
    return await uploadPersonalInformationRequest(form);
  },

  async fetchAndSetUser({ commit }) {
    try {
      const response = await getProfileRequest(this.$cookies.get('accessToken'));

      if (!response.data.success) {
        return false;
      }

      commit('SET_USER', response.data.data);
      return true;
    } catch (e) {
      return false;
    }
  },
}
