import requestManager from '@/requestManager/requestManager';

export default function ({ $cookies }) {
  requestManager.interceptors.request.use(config => {
    if ($cookies.get('accessToken') && !config.headers.Authorization) {
      config.headers.Authorization = 'Bearer ' + $cookies.get('accessToken');
    }

    return config;
  });
};
