import {
  getWalletListRequest,
  deleteWalletItemRequest,
} from '@/requestManager/cabinet/settings/wallet';

export const state = () => ({
  userWallets: [],
})

export const mutations = {
  SET_WALLETS_LIST(state, wallets) {
    state.userWallets = wallets;
  },

  DELETE_WALLET_ITEM(state, id) {
    let removeIndex = state.userWallets.findIndex(wallet => wallet.id === id);

    state.userWallets.splice(removeIndex, 1);
  },

  ADD_WALLET_TO_LIST(state, wallet) {
    state.userWallets.push(wallet);
  },
};

export const actions = {
  // получение списка кошельков
  async fetchWalletList({ commit }) {
    try {
      const response = await getWalletListRequest();

      if (response.data.success) {
        commit('SET_WALLETS_LIST', response.data.data);
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },

  // удаление записи в списке кошельков
  async deleteWalletItem({ commit }, id) {
    return await deleteWalletItemRequest(id);
  },
};