import requestManager from '@/requestManager/requestManager';

const UPLOAD_VERIFICATION_IMAGE_REQUEST = '/api/user-verification/upload-document';
const DELETE_VERIFICATION_IMAGE_REQUEST = '/api/user-verification/delete-document/${id}';
const VERIFICATION_FORM = '/api/user-verification';
const DELETE_VERIFICATION_RESULT ='/api/user-verification/delete-verification-results'
const TW0_FA_REQUEST = '/api/user/get-google-2fa-qr-code';
const CODE_ON_EMAIL = '/api/user/confirmation-codes/send-google-2fa-turn-on';
const CHECK_EMAIL_CODE = '/api/user/confirmation-codes/check-google-2fa-turn-on';

// отправка фото из формы верификации аккаунта
export const uploadAccountVerificationImageRequest = ([imageId, documentType, side]) =>
  requestManager.post(UPLOAD_VERIFICATION_IMAGE_REQUEST,
    {
      imageId: imageId,
      documentType,
      side,
    },
  );

// удаление фото из формы верификации аккаунта
export const deleteAccountVerificationImagesRequest = (imageId) =>
  requestManager.delete(DELETE_VERIFICATION_IMAGE_REQUEST.replace('${id}', imageId));

// отправка формы верификации аккаунта
export const verificationFormRequest = (form) =>
  requestManager.post(VERIFICATION_FORM, form);

// получение инфы для формы верификации аккаунта
export const getAccountVerificationFormInfo = () =>
  requestManager.get(VERIFICATION_FORM);

export const deleteAccountVerificationResult = () =>
  requestManager.post(DELETE_VERIFICATION_RESULT);

// получение инфы для google 2fa
export const get2FAInfoRequest = () =>
  requestManager.get(TW0_FA_REQUEST);

export const getCodeOnEmail = () =>
  requestManager.get(CODE_ON_EMAIL);

export const checkEmailCode = code =>
  requestManager.post(CHECK_EMAIL_CODE, {
    code,
  });

