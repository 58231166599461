import requestManager from '@/requestManager/requestManager';

const GET_REFERRAL_LINK = 'api/user/referrals';
const GET_REFERRAL_LINK_NETWORK = 'api/user/referral-link-count';
const POST_REFERRAL_USER = 'api/user/save-referral';
const GET_REFERRAL_NAME = 'api/users/referral-names';
const GET_MY_REFERRAL_NETWORK = 'api/user/referral-network';
const GET_MY_REFERRAL_WALLET = 'api/user/referral-wallet';
const CREATE_REFERRAL_LINK = 'api/user/create-referral-link';


export const getReferralLinkRequest = () =>
  requestManager.get(GET_REFERRAL_LINK);


export const getReferralNetworkLinkCount = () =>
  requestManager.get(GET_REFERRAL_LINK_NETWORK);

export const getReferralName = () =>
  requestManager.get(GET_REFERRAL_NAME);

export const postReferralUser = (token, referralCode) =>
  requestManager.post(POST_REFERRAL_USER, {
    referral_code: referralCode,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  });


  export const getMyReferralNetWork = () =>
    requestManager.get(GET_MY_REFERRAL_NETWORK);


  export const getMyReferralWallet = () =>
    requestManager.get(GET_MY_REFERRAL_WALLET);


  export const createReferralLink = (token, type) =>
    requestManager.post(CREATE_REFERRAL_LINK, {
      type: type,
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    });