import {
  strategiesRequest,
  investToStrategyRequest,
  withdrawFromStrategyRequest,
} from '@/requestManager/cabinet/services';

export const state = () => ({
  strategies: [],
});

export const getters = {};

export const mutations = {
  SET_STRATEGIES(state, strategies) {
    state.strategies = strategies;
  },
};

export const actions = {
  // получение стратегий
  async getStrategiesRequest({ commit }) {
    try {
      const response = await strategiesRequest();
      
      if (response.data.success) {
        commit('SET_STRATEGIES', response.data.data);
        console.log('SET_STRATEGIES', response.data.data);

      }
    } catch (e) {
      console.log(e)
    }
  },

  // инвестирование в статегию
  async investToStrategy({ commit }, form) {
    return await investToStrategyRequest(form);
  },

  // вывод из стратегииы
  async withdrawIntoStrategy({ commit }, form) {
    return await withdrawFromStrategyRequest(form);
  },
};