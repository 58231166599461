const SETTING_ROUTES = [
  '/cabinet/settings/profile',
  '/cabinet/settings/information',
  '/cabinet/settings/wallet',
  '/cabinet/settings/notifications',
  '/cabinet/settings/analytics',
];

const SECURITY_ROUTES = [
  '/cabinet/security/password',
  '/cabinet/security/confirm-profile',
  '/cabinet/security/verification',
  '/cabinet/security/devices-activity',
]

export const getActiveLink = {
  data() {
    return {
      settingsLinksIsActive: false,
      securityLinksIsActive: false,
    }
  },
  watch: {
    '$route.path': {
      handler() {
        this.shownSettingsActiveLink();
        this.shownSecurityActiveLink();
      },
    },
  },

  methods: {
    shownSettingsActiveLink() {
      return SETTING_ROUTES.includes(this.$route.path)
        ? this.settingsLinksIsActive = true
        : this.settingsLinksIsActive = false;
    },
    shownSecurityActiveLink() {
      return SECURITY_ROUTES.includes(this.$route.path)
        ? this.securityLinksIsActive = true
        : this.securityLinksIsActive = false;
    },
  },
};