export const SERVICES = {
  EARN: 'earn',
  MINE: 'mine',
}

export const STRATEGY_TYPES = {
  BLUE_CONTROL: 'Blue Control',
  BLUE_POINT: 'Blue Point',
  ORANGE_CUBE: 'Orange Cube',
}
export const STRATEGY_ALIAS = {
  BLUE_CONTROL: 'blue-point',
  BLUE_POINT: 'blue-control',
  ORANGE_CUBE: 'orange-cube',
}
