export const TITLE_TYPES = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
}

export const V_BUTTON = {
  BLUE: 'blue',
  TRANSPARENT: 'transparent',
}

export const PRELOADER_TYPES = {
  DOTS: 'dots',
  SPINNER: 'spinning',
}