import {
  amountDepositRequest,
  transactionsRequest,
} from '@/requestManager/cabinet/wallet';

export const state = () => ({
  transactions: [],
});

export const mutations = {
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },
};

export const actions = {
  //получение истории транзакций
  async getTransactionsRequest({ commit }) {
    try {
      const response = await transactionsRequest();

      if (response.data.success) {
        commit('SET_TRANSACTIONS', response.data.data);
      }
    } catch (e) {
      console.log(e)
    }
  },

  // отправка запроса на пополнение счета
  async sendAmountDepositRequest({ commit }, form) {
    return await amountDepositRequest(form);
  },
};