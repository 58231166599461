import requestManager from '@/requestManager/requestManager';

const CONTROL_PROFILE_REQUEST   = 'api/user-verification';

export const getConfirmProfileRequest = () =>
  requestManager.get(CONTROL_PROFILE_REQUEST);

export const confirmProfileRequest = (form) =>
  requestManager.post(CONTROL_PROFILE_REQUEST, form);

