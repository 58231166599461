import Vue from 'vue';
import dayjs from 'dayjs';

// dayjs.extend(require('dayjs/plugin/customParseFormat'));
// dayjs.extend(require('dayjs/plugin/isToday'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));
// require('dayjs/locale/ru');

Vue.use(dayjs);
